/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    'aws_appsync_graphqlEndpoint': 'https://dsha2uuqqjerfkwplhcrwvt3wy.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    
    //"aws_cognito_identity_pool_id": "eu-west-1:89bedcbc-325c-45ce-ae3b-9a8840669427",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ipLU0Hpv",
    "aws_user_pools_web_client_id": "e88me50ubqjfe45083k6d7k42",
    
    "aws_project_region": "eu-west-1",
    "oauth": {
        "domain": 'oblcc-avm.auth.eu-west-1.amazoncognito.com',
        "scope": ['email', 'openid'],
        "redirectSignIn": 'https://avm.oblcc.com',
        "redirectSignOut": 'https://avm.oblcc.com',
        "responseType": 'token',
    },
    "aws_content_delivery_bucket": "accountvending-20200108151632-hostingbucket-oblcctest",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://dy5uc6a4kf4wy.cloudfront.net"
};


export default awsmobile;
