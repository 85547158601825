export default {
  header: {
    self: {},
    items: [
      // {
      //   title: "Accounts",
      //   root: true,
      //   alignment: "left",
      //   page: "accounts",
      //   translate: "MENU.DASHBOARD"
      // }
    ]
  },
  aside: {
    self: {},
    items: [
      // {
      //   title: "Accounts",
      //   root: true,
      //   icon: "flaticon2-architecture-and-city",
      //   page: "accounts",
      //   translate: "MENU.DASHBOARD",
      //   bullet: "dot"
      // }
    ]
  }
};
