import React from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from "react-bootstrap/Button";
import TextField from '@material-ui/core/TextField';
// import HiddenField from '@material-ui/core/Input'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

// export default function SimpleModal() {
class AddAccountModal extends React.Component {


    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);

        // this.classes = makeStyles(theme => ({
        //     paper: {
        //         position: 'absolute',
        //         width: 400,
        //         backgroundColor: theme.palette.background.paper,
        //         boxShadow: theme.shadows[5],
        //         padding: theme.spacing(4),
        //         outline: 'none',
        //     },
        //     textField: {
        //         marginLeft: theme.spacing(1),
        //         marginRight: theme.spacing(1),
        //     },
        //     modal: {
        //         display: 'flex',
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //     },
        // }))();
        this.state = {
            name: '',
            open: false,
            // classes: this.classes,
        };
    }

    handleChange(name, event) {
        this.setState({[name]: event.target.value});
    }

    async submit() {
        const {onCreate} = this.props;
        const input = {
            name: this.state.name,
            customer_id: this.props.customerId
        };
        console.log(input);

        try {
            await onCreate({input});
            this.handleClose();
        } catch (err) {
            console.error(err);
        }
    }

    //
    // [open, setOpen] = React.useState(false);
    // getModalStyle is not a pure function, we roll the style only on the first render
    // const [modalStyle] = React.useState();
    handleOpen() {
        this.setState({open: true});
    }

    handleClose() {
        this.setState({open: false});
    }

    // useStyles() {
    //     makeStyles(theme => ({
    //         paper: {
    //             position: 'absolute',
    //             width: 400,
    //             backgroundColor: theme.palette.background.paper,
    //             boxShadow: theme.shadows[5],
    //             padding: theme.spacing(4),
    //             outline: 'none',
    //         },
    //         textField: {
    //             marginLeft: theme.spacing(1),
    //             marginRight: theme.spacing(1),
    //         },
    //         modal: {
    //             display: 'flex',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //         },
    //     }));
    // }
    // const handleChange = name => event => {
    //     setValues({ ...values, [name]: event.target.value });
    // };
    // const submit = () => {
    //     const { onCreate } = this.props;
    //     const input = {
    //         name: this.state.name,
    //     };
    //     console.log(input);
    //
    //     try {
    //         onCreate({input});
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    // this.classes = useStyles();

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Button variant="primary btn-pill" style={{marginRight: 20}} onClick={this.handleOpen}>Add account</Button>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                    className={classes.modal}
                >
                    <div className={classes.paper}>
                        <Typography variant="h6" id="modal-title">
                            Add account
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            label="Account name"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={(event) => { this.handleChange('name', event)}}
                        />
                        <Button variant="primary btn-pill" onClick={this.submit}>Add account</Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

AddAccountModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(AddAccountModal);
