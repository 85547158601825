import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import { withRouter } from "react-router";
import _ from 'lodash';
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
// import * as subscriptions from "../../../graphql/subscriptions";
import { Connect } from "aws-amplify-react";
import { graphqlOperation } from 'aws-amplify';
import { Portlet, PortletBody, PortletHeader, PortletHeaderTitle, PortletHeaderToolbar } from "../../partials/content/Portlet";
import AddAccountModal from "../../components/simplemodal";
import {
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from "@material-ui/core";

class AccountsPage extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        const { customerId } = this.props.match.params;
        this.state = {
            payload: null,
            customer_id: customerId,
        };
    }

    handleChange(name, event) {
        this.setState({ [name]: event.target.value });
        this.props.history.push(`/accounts/${event.target.value}`);
    }

    render() {
        const CustomerList = ({ customers }) => (
            <FormControl>
                <InputLabel shrink id="customer-select-label">
                    Customer
            </InputLabel>
                <Select
                    labelId="customer-select-label"
                    id="customer-select"
                    value={this.state.customer_id}
                    onChange={(event) => { this.handleChange('customer_id', event) }}
                    displayEmpty
                    style={{
                        minWidth: 120
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {_.map(customers, ({ id, name }) => (
                        <MenuItem key={id} value={id}>{name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );

        const ListView = ({ accounts }) => (
            <div>
                <Table striped hover responsive="xl">
                    <thead>
                        <tr>
                            <th>name</th>
                            <th>account id</th>
                            <th>email</th>
                            <th>creation status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(accounts, ({ id, name, role, email, creation_status, account_id }) => (
                            <tr key={id}>
                                <td>{name}</td>
                                <td>{account_id}</td>
                                <td>{email}</td>
                                <td>{creation_status}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );


        return (
            <>
                <Portlet>
                    <PortletHeader
                        title={
                            <PortletHeaderTitle>Accounts</PortletHeaderTitle>
                        }
                        toolbar={
                            <PortletHeaderToolbar>
                                {
                                    this.state.customer_id ?
                                        <Connect mutation={graphqlOperation(mutations.createAccount)}>
                                            {({ mutation }) => (
                                                <AddAccountModal onCreate={mutation} customerId={this.state.customer_id} />
                                            )}
                                        </Connect> : null
                                }
                                <Connect query={graphqlOperation(queries.listCustomers)}>
                                    {({ data: { listCustomers }, loading, errors }) => {
                                        if (errors && errors.length > 0) return (<h3>Error</h3>);
                                        if (loading || !listCustomers) return (<h3>Loading...</h3>);
                                        return (<CustomerList customers={listCustomers.items.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))} />);
                                    }}
                                </Connect>
                            </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody>
                        {this.state.customer_id ?
                            <Connect
                                query={graphqlOperation(queries.getCustomer, { id: this.state.customer_id })}
                            >
                                {({ data: { getCustomer }, loading, errors }) => {
                                    if (errors && errors.length > 0) return (<h3>Error</h3>);
                                    if (loading || !getCustomer) return (<h3>Loading...</h3>);
                                    return (<ListView accounts={getCustomer.accounts.items.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))} />);
                                }}
                            </Connect> : null
                        }

                    </PortletBody>
                </Portlet>


            </>
        );
    }
};

export default withRouter(AccountsPage);